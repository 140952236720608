import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react-v2/24/outline';
import {
  Cog6ToothIcon,
  UserCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
// import Autocomplete from '@/components/investors/search/autocomplete';
// import { InvestorsSearchGlobalContextProvider } from '@/components/investors/search/global/context';
import { HeroiconV2Type } from '@leaf/helpers';
import CompanyPicker from '@/components/layouts/company-picker';
import TickerPrice from '@/components/layouts/ticker-price-v2';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import routes from '@/utils/routes-v2';

export default function DashboardTopbar() {
  const { currentCompanyProfileUser, sidebarIsCollapsed } =
    useCurrentCompanyProfileUser();
  const { marketListingKey } = currentCompanyProfileUser.profile.ticker;
  const [openPicker, setOpenPicker] = useState(false);

  const { asPath } = useRouter();

  type DashboardIconLinkProps = {
    Icon: HeroiconV2Type;
    current: string;
    href: string;
  };

  const DashboardIconLink = ({
    Icon,
    current,
    href,
  }: DashboardIconLinkProps) => {
    return (
      <Link
        className={clsx(
          current === href
            ? 'bg-amplify-green-50 hover:bg-amplify-green-100'
            : 'hover:bg-gray-50',
          `group rounded-md p-1.5 transition-colors`
        )}
        href={href}
      >
        <Icon
          className={clsx(
            current === href
              ? 'text-amplify-green-600 group-hover:text-amplify-green-800'
              : 'text-gray-600',
            'size-5 transition-colors'
          )}
        />
      </Link>
    );
  };

  const showMenu =
    currentCompanyProfileUser.user.companyProfileUsers.length > 1;

  return (
    <div
      className={clsx(
        'z-navbar sticky top-0 hidden flex-row justify-between border-b border-gray-200 bg-white px-4 py-1 md:flex',
        sidebarIsCollapsed
          ? 'ml-[53px] w-[calc(100%-53px)]'
          : 'ml-[238px] w-[calc(100%-238px)]'
      )}
    >
      {(currentCompanyProfileUser.profile.paymentOverdueMessage && (
        <div className="my-auto flex items-center gap-2 rounded-md bg-red-50 px-3 py-1">
          <ExclamationTriangleIcon className="size-4 text-red-500" />
          <span className="text-sm text-red-700">
            {currentCompanyProfileUser.profile.paymentOverdueMessage}
          </span>
        </div>
      )) || <span className="flex bg-white" />}
      {/* search coming soon */}
      {/* <InvestorsSearchGlobalContextProvider>
        <div className="w-full pt-2">
          <Autocomplete fullWidth={true} />
        </div>
      </InvestorsSearchGlobalContextProvider> */}
      <div className="flex flex-row flex-nowrap items-center justify-end gap-4">
        <div className="flex flex-row items-center gap-3">
          <DashboardIconLink
            Icon={UserCircleIcon}
            current={asPath}
            href={routes.myAccount.profile.href(marketListingKey)}
          />
          <DashboardIconLink
            Icon={Cog6ToothIcon}
            current={asPath}
            href={routes.settings.general.href(marketListingKey)}
          />
        </div>
        <div
          className={clsx(
            'flex flex-row items-center gap-3 rounded p-2',
            showMenu ? 'cursor-pointer hover:bg-gray-50 ' : ''
          )}
          onClick={() => setOpenPicker(!openPicker)}
        >
          <TickerPrice />
          {showMenu && <ChevronDownIcon className="size-4 text-gray-400" />}
        </div>
      </div>
      {showMenu && (
        <div
          className={clsx(
            'absolute right-6 top-20 bg-white transition-all  duration-300',
            openPicker
              ? 'z-50 opacity-100'
              : 'pointer-events-none -z-50 opacity-0'
          )}
        >
          <CompanyPicker />
        </div>
      )}
    </div>
  );
}
