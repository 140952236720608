import { Typography } from '@ds';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react-v2/24/solid';
import capitalize from 'lodash/capitalize';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { logout } from '@/utils/auth-helpers';
import routes from '@/utils/routes';

const SimulateBar: React.ComponentType = () => {
  const { formatAndShowError } = useAlert();
  const { currentCompanyProfileUser } = useCurrentCompanyProfileUser();

  async function handleLogout() {
    try {
      await logout();

      window.location.assign(routes.auth.login.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }

  if (currentCompanyProfileUser.simulatingAdminUserId) {
    return (
      <div className="z-navbar fixed bottom-4 left-1/2 mx-auto hidden w-[95%] -translate-x-1/2 flex-nowrap items-center justify-between gap-3 rounded-lg bg-amber-50 p-3 shadow-lg md:flex md:w-auto">
        <UserCircleIcon className="ml-2 size-5 text-amber-600" />
        <Typography
          className="!font-semibold text-amber-800"
          variant="text-body-md"
        >
          Simulating{' '}
          {`${currentCompanyProfileUser.user.email} (${capitalize(
            currentCompanyProfileUser.companiesRole?.description ?? ''
          )})`}
        </Typography>
        <button
          className="flex items-center rounded-md p-2 transition-colors hover:bg-amber-500/10"
          type="button"
          onClick={handleLogout}
        >
          <LogoutIcon className="size-5 text-amber-600" strokeWidth={1.5} />
        </button>
      </div>
    );
  }

  return null;
};

export default SimulateBar;
