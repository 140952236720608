// DS V2
import { Fragment, ReactNode, useEffect, useState } from 'react';
import analytics from '@analytics';
import { Tooltip } from '@ds';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react-v2/24/outline';
import {
  UserCircleIcon,
  XMarkIcon,
  GiftIcon,
  Bars3BottomLeftIcon,
  ArrowRightStartOnRectangleIcon,
  QuestionMarkCircleIcon,
  GlobeAsiaAustraliaIcon,
  Cog6ToothIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import * as contentful from 'contentful';
import Image from 'next/image';
import { useRouter } from 'next/router';
import CookieBanner from '@/components/cookie-manager/cookie-banner';
import CompanyPicker from '@/components/layouts/company-picker';
import DashboardMenu from '@/components/layouts/dashboard-menu';
import DashboardMenuItem from '@/components/layouts/dashboard-menu-item';
// import HolidayClosureModal from '@/components/layouts/holiday-closure-modal';
import DashboardTopbar from '@/components/layouts/dashboard-topbar';
import ImportOutdatedBanner from '@/components/layouts/import-outdated-banner';
import SimulateBar from '@/components/layouts/simulate-bar';
import TickerPrice from '@/components/layouts/ticker-price-v2';
import ChangelogModal, {
  AthenaChangelogEntry,
  EntriesResponseObject,
} from '@/components/modals/changelog-modal';
import { useAlert } from '@/contexts/alert-context';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { logout } from '@/utils/auth-helpers';
import {
  getChangelogCookie,
  setChangelogCookie,
} from '@/utils/changelog-cookies';
import { INTERCOM_ARTICLES } from '@/utils/intercom-articles';
import routes from '@/utils/routes';
import { TRACKING_EVENTS } from '@/utils/tracking-events';

interface Props {
  children?: ReactNode;
  maxWidth?: string;
}

const Dashboard: React.ComponentType<Props> = ({
  children,
  maxWidth = 'max-w-[1136px]',
}) => {
  const [changelogData, setChangelogData] = useState<EntriesResponseObject>();
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [changelogUnreadCount, setChangelogUnreadCount] = useState(0);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);

  const {
    currentCompanyProfileUser,
    isComputershare,
    isDemo,
    isPremium,
    isTrial,
    isUK,
    liveInvestorHubBaseUrl,
    setSidebarIsCollapsed,
    sidebarIsCollapsed,
  } = useCurrentCompanyProfileUser();

  const { marketListingKey } = currentCompanyProfileUser.profile.ticker;

  const { asPath } = useRouter();

  const { formatAndShowError } = useAlert();

  async function handleSignOut() {
    try {
      await logout();

      window.location.assign(routes.auth.login.href);
    } catch (error) {
      formatAndShowError(error);
    }
  }

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API &&
      process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    ) {
      const client = contentful.createClient({
        accessToken: `${process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API}`,
        environment: 'master',
        space: `${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
      });

      client
        .getEntries<AthenaChangelogEntry>({
          content_type: 'athenaChangelog',
          'fields.productTier[in]': isPremium
            ? ['all', 'premium']
            : ['all', 'base'],
          'fields.region[in]': isUK ? ['all', 'uk'] : ['all', 'au'],
          limit: 10,
          order: ['-sys.createdAt'],
        })
        .then((data) => {
          setChangelogData(data);

          setChangelogUnreadCount(() => {
            const readChangelogIds =
              getChangelogCookie()?.readChangelogIds ?? [];

            return data.items.reduce((acc, curr) => {
              if (!readChangelogIds.includes(curr.sys.id)) {
                acc += 1;
              }

              return acc;
            }, 0);
          });
        });
    }
  }, [isPremium, isUK]);

  const showMenu =
    currentCompanyProfileUser.user.companyProfileUsers.length > 1;

  if (typeof sidebarIsCollapsed === 'undefined') return null;

  return (
    <div className="flex min-h-screen flex-col bg-gray-50">
      {/* mobile sidebar animation */}
      <Transition.Root as={Fragment} show={sidebarOpen}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col border-r border-gray-200 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-20 -mr-14">
                    <button
                      className="flex h-10 w-10 items-center justify-center"
                      type="button"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        aria-hidden="true"
                        className="size-6 text-white"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="mt-16 flex flex-1 flex-col gap-4 overflow-y-auto py-4">
                  <DashboardMenu />
                </div>
                <div className="shrink-0 space-y-1 border-t border-gray-200 px-2 py-4">
                  <DashboardMenuItem
                    Icon={UserCircleIcon}
                    current={asPath.startsWith(
                      routes.myAccount.href(marketListingKey)
                    )}
                    href={routes.myAccount.profile.href(marketListingKey)}
                    title="My account"
                  />
                  <DashboardMenuItem
                    Icon={Cog6ToothIcon}
                    current={asPath.startsWith(
                      routes.settings.href(marketListingKey)
                    )}
                    href={routes.settings.general.href(marketListingKey)}
                    title="Settings"
                  />
                  <DashboardMenuItem
                    Icon={GlobeAsiaAustraliaIcon}
                    href={liveInvestorHubBaseUrl}
                    target="_blank"
                    title="View investor hub"
                  />
                  <DashboardMenuItem
                    Icon={QuestionMarkCircleIcon}
                    href={INTERCOM_ARTICLES.home}
                    target="_blank"
                    title="Get help"
                  />
                  <DashboardMenuItem
                    Icon={ArrowRightStartOnRectangleIcon}
                    title="Log out"
                    onClick={handleSignOut}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div aria-hidden="true" className="w-14 shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <SimulateBar />
      <DashboardTopbar />

      {/* Static sidebar for desktop */}
      <div
        className={clsx(
          'z-navbar hidden transition-[width] duration-300 ease-in-out md:fixed md:inset-y-0 md:flex md:flex-col',
          sidebarIsCollapsed ? 'md:w-[52px]' : 'md:w-[238px]'
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div
            className={clsx(
              'flex flex-1 flex-col gap-1 overflow-hidden overflow-y-auto transition-[padding] duration-300 ease-in-out',
              sidebarIsCollapsed ? 'pt-[24px]' : 'pt-[17px]'
            )}
          >
            {sidebarIsCollapsed ? (
              <>
                <div className="mb-4 flex flex-row items-center gap-2 px-4">
                  <Image
                    alt="Investorhub logo"
                    height={20}
                    src="/logos/investorhub-logo.svg"
                    width={20}
                  />
                </div>

                <div
                  className="absolute left-[52px] top-4 cursor-pointer rounded-md rounded-l-none border border-gray-200 bg-white p-2"
                  onClick={() => setSidebarIsCollapsed(false)}
                >
                  <Tooltip description="Show menu" place="left">
                    <ChevronRightIcon className="size-4" />
                  </Tooltip>
                </div>
              </>
            ) : (
              <div className="mb-[9px] flex flex-row items-center justify-between gap-2 px-4">
                <Image
                  alt="Investorhub logo"
                  height={20}
                  src="/logos/investorhub-full.svg"
                  width={125}
                />
                {(isDemo || isTrial) && (
                  <span className="bg-amplify-green-50 text-amplify-green-700 rounded-full px-1.5 pt-0.5 text-xs font-bold uppercase">
                    {isTrial ? 'TRIAL' : isDemo ? 'DEMO' : ''}
                  </span>
                )}
                <Tooltip description="Hide menu" place="right">
                  <div
                    className="cursor-pointer rounded-md border border-gray-200 p-2"
                    onClick={() => setSidebarIsCollapsed(true)}
                  >
                    <ChevronLeftIcon className="size-4" />
                  </div>
                </Tooltip>
              </div>
            )}

            <DashboardMenu />
          </div>
          <div className="flex h-[172px] shrink-0 flex-col justify-between space-y-1 border-t border-gray-200 p-2">
            <DashboardMenuItem
              Icon={GlobeAsiaAustraliaIcon}
              href={liveInvestorHubBaseUrl}
              target="_blank"
              title="View investor hub"
            />
            <DashboardMenuItem
              Icon={GiftIcon}
              badge={changelogUnreadCount}
              title="What's new?"
              onClick={() => {
                analytics.track(TRACKING_EVENTS.changelog.modalOpened, {
                  unreadCount: changelogUnreadCount,
                });
                setChangelogOpen(true);

                const readIds = [
                  ...(getChangelogCookie()?.readChangelogIds ?? []),
                ];
                changelogData?.items.forEach((item) => {
                  if (readIds.indexOf(item.sys.id) === -1) {
                    readIds.push(item.sys.id);
                  }
                });
                setChangelogCookie({ readChangelogIds: readIds });

                setChangelogUnreadCount(0);
              }}
            />
            <DashboardMenuItem
              Icon={QuestionMarkCircleIcon}
              href={INTERCOM_ARTICLES.home}
              target="_blank"
              title="Get help"
            />
            <DashboardMenuItem
              Icon={ArrowRightStartOnRectangleIcon}
              title="Log out"
              onClick={handleSignOut}
            />

            <ChangelogModal
              data={changelogData}
              marketListingKey={marketListingKey}
              open={changelogOpen}
              setOpen={setChangelogOpen}
            />
          </div>
        </div>
      </div>
      {/* mobile navbar (not sidebar) */}
      <div
        className={clsx(
          'flex flex-1 flex-col transition-[padding] duration-300 ease-in-out',
          sidebarIsCollapsed ? 'md:pl-[52px]' : 'md:pl-[238px]'
        )}
      >
        <div className="sticky top-0 z-50 flex h-16 w-full shrink-0 items-center justify-between bg-white md:hidden md:bg-secondary-grey-light-2">
          <button
            className="border-r border-gray-200 px-4 text-primary-off-black focus:outline-none focus:ring-0 md:hidden"
            type="button"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon aria-hidden="true" className="size-6" />
          </button>
          <div
            className={clsx(
              'mr-2 flex flex-row items-center justify-end gap-3 rounded p-2',
              showMenu ? 'cursor-pointer hover:bg-gray-50 ' : ''
            )}
            onClick={() => setOpenPicker(!openPicker)}
          >
            <TickerPrice />
            {showMenu && <ChevronDownIcon className="size-4 text-gray-400" />}
          </div>
          {/* mobile company picker */}
          {showMenu && (
            <div
              className={clsx(
                'absolute right-4 top-20 bg-white transition-all duration-300',
                openPicker
                  ? 'z-50 opacity-100'
                  : 'pointer-events-none -z-50 opacity-0'
              )}
            >
              <CompanyPicker />
            </div>
          )}
        </div>
        <main className="flex-1 p-6">
          <div className={`mx-auto mb-20 ${maxWidth}`}>{children}</div>
        </main>
      </div>
      {/* Re-enable this for holidays
      <HolidayClosureModal /> */}

      {isPremium && isComputershare && <ImportOutdatedBanner />}
      <CookieBanner />
    </div>
  );
};

export default Dashboard;
