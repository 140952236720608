import { HTMLAttributeAnchorTarget } from 'react';
import { Badge, Typography, Tooltip } from '@ds';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react-v2/24/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { HeroiconV2Type } from '@leaf/helpers';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';

interface Props {
  Icon: HeroiconV2Type;
  badge?: number;
  children?: React.ReactNode;
  current?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  target?: HTMLAttributeAnchorTarget;
  title: string;
}

const DashboardMenuItem: React.ComponentType<Props> = ({
  Icon,
  badge,
  current,
  href,
  onClick,
  target,
  title,
}) => {
  const { sidebarIsCollapsed } = useCurrentCompanyProfileUser();

  const Wrapper: React.ComponentType<{
    children: React.ReactNode;
  }> = ({ children }) => {
    const className = clsx(
      current
        ? 'bg-amplify-green-50 text-amplify-green-700'
        : 'text-gray-600 hover:bg-gray-50',
      'group flex h-9 w-full items-center gap-2.5 rounded px-2 py-1.5 text-left transition-colors'
    );

    if (href) {
      return (
        <Link className={className} href={href} target={target}>
          {children}
        </Link>
      );
    }

    return (
      <button className={className} type="button" onClick={onClick}>
        {children}
      </button>
    );
  };

  const renderContent = () => {
    return (
      <Wrapper>
        <Icon aria-hidden="true" className="size-4" />
        {sidebarIsCollapsed ? null : (
          <>
            <Typography
              className="min-w-0 flex-1 truncate text-left"
              variant="text-button-md"
            >
              {title}
            </Typography>
            {badge ? <Badge color="amp-green">{badge}</Badge> : null}
            {badge === 0 ? <Badge color="gray">{badge}</Badge> : null}
            {target && (
              <ArrowTopRightOnSquareIcon className="size-4 text-gray-400" />
            )}
          </>
        )}
      </Wrapper>
    );
  };

  if (sidebarIsCollapsed) {
    return (
      <Tooltip description={title} place="right">
        {renderContent()}
      </Tooltip>
    );
  }

  return renderContent();
};

export default DashboardMenuItem;
